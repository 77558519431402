'use client';

import { Spinner } from '@/components/Spinner/Spinner';
import { AppAction } from '@/contexts/TokenContext/Roles';
import { useIsAllowed } from '@/contexts/TokenContext/useIsAllowed';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function AuthenticatedPage(): React.ReactNode {
    const router = useRouter();
    const hasAccessToSummary = useIsAllowed(AppAction.SUMMARY);
    const hasAccessToPremises = useIsAllowed(AppAction.SEARCH_ORDERS);

    useEffect(() => {
        if (!router) {
            return;
        }

        // If they have access to the summary page, use that as the "default"
        if (hasAccessToSummary) {
            router.replace('/summary/');
            return;
        }

        // If they have access to the premises page, use that as the backup
        if (hasAccessToPremises) {
            router.replace('/premises/');
            return;
        }

        // If they don't have access to either, they'll get stuck on the "spinner"
        // (That should cover everyone who's supposed to have access)
        // TODO: forward them to an error page telling them to request access
    }, [router, hasAccessToPremises, hasAccessToSummary]);

    return <Spinner />;
}
